import React, { ReactNode, useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import classNames from 'classnames'
import Row from 'Components/Row/Row'
import Button from 'Components/touchables/Button'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import styles from './CategoriesFilter.module.scss'

const ALL = 'all'

export type CategoriesFilterParams = { selectedItems: Array<Record<string, any>> }

export type CategoriesFilterProps = {
  className?: string
  contentClassName?: string
  data: Record<string, any>
  entityName: string
  entitiesName: string
  categoryName: string
  renderList: (params: CategoriesFilterParams) => ReactNode
  withContainer?: boolean
}

export const CategoriesFilter = (props: CategoriesFilterProps) => {
  const {
    className = '',
    contentClassName = '',
    data = {},
    entityName,
    entitiesName,
    categoryName,
    renderList,
    withContainer = true,
    ...other
  } = props

  const { t } = useTranslation()

  const [activeCategory, setActiveCategory] = useState(ALL)

  const categories = [
    ALL,
    ...Array.from(
      new Set<string>(
        data[entitiesName]?.map(
          (i: Record<string, any>) => i[entityName]?.[categoryName],
        ) || [],
      ),
    ),
  ]

  const selectedItems = data[entitiesName]?.filter((i: Record<string, any>) => {
    return [ALL, i[entityName]?.[categoryName]].includes(activeCategory)
  })

  const content = (
    <Row className={classNames([styles.filters, className])} {...other}>
      {categories.map((category) => (
        <Button
          key={category}
          className={styles.filter}
          title={category === 'all' ? t(category) : category}
          onClick={() => setActiveCategory(category)}
          variant={category === activeCategory ? 'filled' : 'outline'}
        />
      ))}
    </Row>
  )

  return (
    <>
      {withContainer ? (
        <BaseContainer
          vertical="xs"
          className={styles.container}
          contentClassName={classNames([styles.content, contentClassName])}
        >
          {content}
        </BaseContainer>
      ) : (
        content
      )}
      {renderList?.({ selectedItems })}
    </>
  )
}
